<template>
  <div>
    
    <b-card>
      <p>Edit Relation with {{ modelInformation.toSelect }}</p>
      <span class="horizontal-center" v-if="mainLoading">
        <b-spinner  style=" width: 6rem;height: 6rem;"></b-spinner>
      </span>

      <span v-if="!mainLoading">

      
      <b-row style="align-items: end">
        <b-col md="11">
          <b-form-select
            v-model="selected"
            :options="options"
            class="mt-3"
          ></b-form-select>
        </b-col>
        <b-col md="1">
          <b-button @click="add" variant="success"
            ><b-spinner v-if="loading" small></b-spinner>Add</b-button
          >
        </b-col>
        <br />
      </b-row>
      <br />
      <b-row>
         <div
        class="relation-button"
        style="margin:10px"
        v-for="(rel, index) in relations"
        :key="index"
      >
        <b-spinner v-if="loading" small></b-spinner>
        <span v-if="modelInformation.model == 'Inerventions' || modelInformation.model == 'Knowledge'">{{
          rel.sector.header
        }}</span>
        <span v-if="modelInformation.model == 'Components' || modelInformation.model == 'Projects'">{{
          rel.header
        }}</span>
        <span v-if="modelInformation.model == 'news'">{{ rel.tag.name }}</span>

        <b-button
          @click="remove(relations[index].id)"
          size="sm"
          variant="outline-danger"
          pill
          >X</b-button
        >
      </div>
      </b-row>
      
     </span>
    </b-card>
  </div>
</template>
<script>
import Vue from "vue";
import {
  getFields,
  getFieldsRelationOneToMany,
  getFieldsRelationPivot,
  pathField,
  postFields,
  removeRelation,
} from "../../assets/js/service";

export default {
  props: ["modelInformation"],
  data() {
    return {
      relations: null,
      selected: null,
      loading: false,
      mainLoading:false,
      options: [{ value: null, text: "Select " }],
    };
  },
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  mounted() {
    this.mainLoading = true;
    this.updateOptionsList();
  },
  methods: {
    updateOptionsList() {
      let token = localStorage.getItem("token");
      if (this.modelInformation.type == "pivot") {
        getFieldsRelationPivot(
          this.modelInformation.model,
          this.modelInformation.id,
          this.modelInformation.relation,
          token,
          this.modelInformation.include
        ).then((resp) => {
          this.relations = resp.data;

          getFields(this.modelInformation.toSelect, token).then((resp) => {
 
            let relation = this.relations;
            let tags = resp.data;
            this.mainLoading = false;
            this.options = [{ value: null, text: "Select " }];
            if (this.modelInformation.model == "news") {
              const result2 = tags.filter(function (o1) {
                return !relation.some(function (o2) {
                  return o1.id == o2.tag.id;
                });
              });
              result2.forEach((element) => {
                this.options.push({ value: element.id, text: element.name });
              });
            }else if(this.modelInformation.model == 'Inerventions' || this.modelInformation.model =='Knowledge'){
              const result2 = tags.filter(function (o1) {
                return !relation.some(function (o2) {
                  return o1.id == o2.sector.id;
                });
              });
              result2.forEach((element) => {
                this.options.push({ value: element.id, text: element.header });
              });
            }

            
          
          });
        });
      }
      else if(this.modelInformation.type == "one to many"){
                getFieldsRelationOneToMany(
          this.modelInformation.model,
          this.modelInformation.id,
          this.modelInformation.relation,
          token
        ).then((resp) => {

          
          this.relations = resp.data;
           getFields(this.modelInformation.toSelect, token).then((resp) => {
        this.allInterventions = resp.data;
        let inter;
        if(this.modelInformation.model == "Components"){
        inter = resp.data.filter(
          (intervention) => intervention.componentId == null
        );
        }else if(this.modelInformation.model == "Projects"){
        inter = resp.data.filter(
          (components) => components.projectId == null
        );
        }
        this.options =  [{ value: null, text: "Select " }];
        inter.forEach(element => {
          this.options.push({value:element.id,text:element.header})
        });
        
        this.mainLoading = false;
      });
          });
      }
    },
    remove(id) {
      let token = localStorage.getItem("token");
      this.$store.commit("set", ["loading", true]);
      if (this.modelInformation.type == "pivot") {
      removeRelation(
        this.modelInformation.model,
        this.modelInformation.id,
        this.modelInformation.relation,
        id,
        token
      ).then((resp) => {
        this.updateOptionsList();
        this.$store.commit("set", ["loading", false]);
        Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      
      });
      }else if(this.modelInformation.type == "one to many"){
        let data;
        if(this.modelInformation.model == "Components"){
          data = {componentId:null};
        }else if(this.modelInformation.model == "Projects"){
          data = {projectId:null};
        }
        pathField(this.modelInformation.toSelect,id,data).then(resp=>{
          this.updateOptionsList();
          this.$store.commit("set", ["loading", false]);
Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      
        })
      }
    },
    add() {
      this.$store.commit("set", ["loading", true]);
      if (this.modelInformation.type == "pivot") {
      let data;
      if (this.modelInformation.model == "news") {
        data = {
          tagId: this.selected,
          newsId: this.modelInformation.id,
        };
      }else if(this.modelInformation.model == "Inerventions"){
       
          data = {
          sectorId: this.selected,
          inerventionId: this.modelInformation.id,
        };
      }
      else if(this.modelInformation.model == "Knowledge"){
       
          data = {
          sectorId: this.selected,
          knowledgeId: this.modelInformation.id,
        };
      }
      let token = localStorage.getItem("token");
      postFields(this.modelInformation.relation, data, token).then((resp) => {
        this.updateOptionsList();
        this.$store.commit("set", ["loading", false]);
        Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    }else if(this.modelInformation.type == "one to many"){
      let data;
        if(this.modelInformation.model == "Components"){
          data = {componentId:this.modelInformation.id};
        }else if(this.modelInformation.model == "Projects"){
          data = {projectId:this.modelInformation.id};
        }
        pathField(this.modelInformation.toSelect,this.selected,data).then(resp=>{
          this.updateOptionsList();
          this.$store.commit("set", ["loading", false]);
Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      
        })
      }
    }
  }
};
</script>
<style>
.relation-button {
  width: fit-content;
  border: 1px solid black;
  padding: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 13px;
}
</style>