<template>
  <div>
    <b-container>
      <b-card>
        <b-card-header>
          <h3 class="text-center">Edit {{ addInformation.title }}</h3>
        </b-card-header>
        <b-card-body>
          <br />

          <img
            v-if="dataToEdit.bannerImages"
            :src="dataToEdit.bannerImages"
            alt=""
            width="100%"
          />
          <img
            v-if="dataToEdit.header_image"
            :src="dataToEdit.header_image"
            alt=""
            width="100%"
          />
          <img
            v-if="dataToEdit.image"
            :src="dataToEdit.image"
            alt=""
            width="50%"
          /><br />
          <img
            v-if="dataToEdit.tumblin"
            :src="dataToEdit.tumblin"
            alt=""
            width="50%"
          /><br />
          <label
            for="header"
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Component'
            "
            >{{ addInformation.title }} Image</label
          >
          <label for="header" v-if="addInformation.title == 'People'"
            >Photo</label
          >
          <ImagPicker
            v-if="addInformation.title != 'Component'"
            :imageData="{ maxNoImg: 1, imageNo: 0 }"
          ></ImagPicker>
          <p v-if="bannerImageEmpity" class="text-center" style="color: red">
            Image Can't be empty
          </p>

          <span v-if="addInformation.title == 'Project'">
            <img :src="dataToEdit.logo_image" alt="" width="20%" />
            <br />
            <label for="">Logo</label>
            <ImagPicker :imageData="{ maxNoImg: 1, imageNo: 1 }"></ImagPicker>
            <p v-if="iconEmpity" class="text-center" style="color: red">
              Logo Can't be empty
            </p>
          </span>

          <span v-if="addInformation.title == 'Project'">
            <label for="">Theme Color</label>
            <div :style="{ background: theme }">
              <color-picker
                theme="light"
                :color="'#' + dataToEdit.theme"
                :sucker-hide="false"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                @changeColor="changeColor"
              />
            </div>
          </span>

          <span
            v-if="
              addInformation.title == 'Project' ||
              addInformation.title == 'Knowledge'
            "
          >
            <label for=""></label>
            <b-input placeholder="Year" v-model="dataToEdit.years"> </b-input>
            <p v-if="yearsEmpity" class="text-center" style="color: red">
              Year Can't be empty
            </p>
          </span>

          <span v-if="addInformation.title == 'Project'">
            <label for="">WebSite</label>
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-globe"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="dataToEdit.website"
                placeholder="Website"
              ></b-form-input>
            </b-input-group>
            <label for="">Socal Media</label>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-twitter"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="dataToEdit.twitter"
                placeholder="twitter link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-facebook"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="dataToEdit.facebook"
                placeholder="facebook link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-instagram"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="dataToEdit.instagram"
                placeholder="instagram link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-linkedin"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="dataToEdit.linkedin"
                placeholder="linkedin link"
              ></b-form-input>
            </b-input-group>
          </span>

          <label for="header" v-if="addInformation.title == 'People'"
            >Full Name</label
          >
          <b-textarea
            v-if="addInformation.title == 'People'"
            v-model="dataToEdit.fullName"
          >
          </b-textarea>
          <p class="text-center" style="color: red" v-if="fullNameerr">
            full name cant be empty
          </p>

          <label for="header">Header</label>
          <b-textarea v-model="dataToEdit.header"> </b-textarea>
          <p class="text-center" style="color: red" v-if="headererr">
            header cant be empty
          </p>

          <div v-if="addInformation.title == 'News'">
            <label for="example-datepicker">Choose a date</label>

            <b-input-group class="mb-3">
              <b-form-input
                id="example-input"
                v-model="dataToEdit.actualDate"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="dataToEdit.actualDate"
                  button-only
                  right
                  locale="en-US"
                  aria-controls="example-input"
                  @context="onContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <label for="header" v-if="addInformation.title == 'Project'"
            >Client</label
          >

          <b-input
            v-if="addInformation.title == 'Project'"
            v-model="dataToEdit.client"
          >
          </b-input>
          <p class="text-center" style="color: red" v-if="clienterr">
            Client can't be empty
          </p>

          <label for="header" v-if="addInformation.title == 'Project'"
            >Target</label
          >
            <div
            class="mb-2"
            v-if="
              
              addInformation.title == 'Project'
            "
          >
          <b-button variant="outline-dark" @click="dataToEdit.target.push({
            number:null,
            title:null
          })">+</b-button>
            <div class="col-md-12" v-for="(element,index) in dataToEdit.target" :key="index">
              <div class="card">
                <b-input-group>
                   <b-form-input
              
              
              placeholder="Title"
              v-model="dataToEdit.target[index].title"
            ></b-form-input>
            <b-form-input
           
              type="number"
              oninput="if(this.value < 0) this.value = 0;"
              placeholder="Target"
              v-model="dataToEdit.target[index].number"
            ></b-form-input>
                </b-input-group>
               
            
              </div>

            </div>
            </div>
          <p class="text-center" style="color: red" v-if="targeterr">
            Target can't be empty
          </p>

          <label
            for="header"
            v-if="
              addInformation.title == 'Intervention' ||
              addInformation.title == 'Project'
            "
            >Regions</label
          >
          <b-input
            v-if="
              addInformation.title == 'Intervention' ||
              addInformation.title == 'Project'
            "
            v-model="dataToEdit.regions"
          >
          </b-input>
          <p class="text-center" style="color: red" v-if="regionsterr">
            Regions can't be empty
          </p>

          <div v-if="addInformation.title != 'Sector'">
           <label for="">Details</label>
         <quill-editor v-model="dataToEdit.details"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
            <p v-if="detailserr" class="text-center" style="color: red">
              Detail Can't be empty
            </p>
            
          </div>

          {{ dataToEdit.fileType }}
          <span v-if="addInformation.title == 'Knowledge'">
            <label for="header">File Type</label>
            <b-form-select
              v-model="dataToEdit.file_type"
              :options="optionsForFileType"
            ></b-form-select>
          </span>
          <br />
          <span
            v-if="
              addInformation.title == 'Knowledge' &&
              dataToEdit.file_type == 'pdf'
            "
          >
            <label for="header">No of pages</label>
            <b-input type="number" v-model="dataToEdit.no_of_pages"></b-input>
            <p class="text-center" style="color: red" v-if="noOfPageserr">
              number of pages cant be empty
            </p>
          </span>
          <br />

          <span v-if="addInformation.title == 'News'">
            <label for="header">Quotes</label>
            <b-textarea v-model="dataToEdit.quotes"> </b-textarea>
          </span>

          <label
            for="header"
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Intervention' &&
              addInformation.title != 'Sector' &&
              addInformation.title != 'Component' &&
              addInformation.title != 'Project' &&
              addInformation.title != 'Knowledge'
            "
            >{{ addInformation.title + " Image" }}
          </label>
          <b-row>
            <b-col
              md="4"
              v-for="(image, index) in dataToEdit.newsImages"
              :key="index"
            >
              <img :src="image" alt="" width="100%" />
            </b-col>
          </b-row>
          <ImagPicker
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Intervention' &&
              addInformation.title != 'Sector' &&
              addInformation.title != 'Component' &&
              addInformation.title != 'Project' &&
              addInformation.title != 'Knowledge'
            "
            :imageData="{ maxNoImg: 10, imageNo: 1 }"
          ></ImagPicker>

          <span
            v-if="
              addInformation.title == 'Knowledge' &&
              dataToEdit.file_type == 'pdf'
            "
          >
            <label for="">PDF Upload</label>
            <br />
            <a :href="dataToEdit.pdf" target="_top">PDF</a>
            <ImagPicker
              :imageData="{ maxNoImg: 1, imageNo: 'filePDF' }"
            ></ImagPicker>
            <p class="text-center" style="color: red" v-if="filePdferr">
              you have to upload the pdf file
            </p>
            <p class="text-center" style="color: red" v-if="fileSizeerr">
              there is some error on uploading your pdf
            </p>
          </span>
        </b-card-body>
        <b-card-body v-if="addInformation.title == 'News'">
          <h3>NEWS detail Information</h3>
          <div class="horizontal-right">
            <b-button
              @click="
                if (dataToEdit.subNews.length < 10) {
                  dataToEdit.subNews.push({
                    header: null,
                    details: null,
                    quotes: null,
                  });

                  subNewsError.push({
                    headererr: null,
                    detailserr: null,
                  });
                }
              "
              >+</b-button
            >
          </div>
          <p
            class="horizontal-right error-message"
            v-if="dataToEdit.subNews.length == 10"
          >
            you cant add more than 10
          </p>
          <div class="row">
            <b-card
              class="col-md-6"
              v-for="(sub, index) in dataToEdit.subNews"
              :key="index"
            >
              <b-card-body>
                No :{{ index + 1 }} <br />
                <label for="header">Banner Image</label>
                <img
                  v-if="dataToEdit.subNews"
                  :src="sub.bannerImages"
                  alt=""
                  width="100%"
                />
                <ImagPicker
                  :imageData="{ maxNoImg: 1, imageNo: 3 + index }"
                ></ImagPicker>

                <label for="header">Header</label>
                <b-textarea v-model="dataToEdit.subNews[index].header">
                </b-textarea>

                <p
                  class="text-center"
                  style="color: red"
                  v-if="subNewsError[index].headererr"
                >
                  header cant be empty
                </p>
                <label for="header">Details</label>
                <b-textarea
                  v-model="dataToEdit.subNews[index].details"
                ></b-textarea>
                <p
                  class="text-center"
                  style="color: red"
                  v-if="subNewsError[index].detailserr"
                >
                  detail cant be empty
                </p>
                <span v-if="addInformation.title == 'News'">
                  <label for="header">Quotes</label>
                  <b-textarea v-model="dataToEdit.subNews[index].quotes">
                  </b-textarea>
                </span>
              </b-card-body>
            </b-card>
          </div>
        </b-card-body>
        <b-card-body>
          <span
            v-if="
              addInformation.title == 'Knowledge' &&
              dataToEdit.file_type == 'video'
            "
          >
            <span v-html="dataToEdit.youtube"></span><br />

            <label for="">youtube Embedede tag</label>
            <b-textarea v-model="dataToEdit.youtube"> </b-textarea>
            <p class="text-center" style="color: red" v-if="youtubeerr">
              youTube embedede link cant be empty
            </p>
          </span>
          <!-- select for sectors for inter  -->
        </b-card-body>
        <b-card-body  v-if="addInformation.title == 'News'">
          <EditRelation :modelInformation="{id:dataToEdit.id,model:'news',toSelect:'tags',relation:'tag-news',include:'tag',type:'pivot'}"/>
        </b-card-body>
         <b-card-body  v-if="addInformation.title == 'Intervention'">
          <EditRelation :modelInformation="{id:dataToEdit.id,model:'Inerventions',toSelect:'Sectors',relation:'inerventionSectors',include:'sector',type:'pivot'}"/>
        </b-card-body>
         <b-card-body  v-if="addInformation.title == 'Knowledge'">
          <EditRelation :modelInformation="{id:dataToEdit.id,model:'Knowledge',toSelect:'Sectors',relation:'knowledgeSectors',include:'sector',type:'pivot'}"/>
        </b-card-body>
        <b-card-body  v-if="addInformation.title == 'Component'">
          <EditRelation :modelInformation="{id:dataToEdit.id,model:'Components',toSelect:'Inerventions',relation:'inerventions',include:'sector',type:'one to many'}"/>
        </b-card-body>
        <b-card-body  v-if="addInformation.title == 'Project'">
          <EditRelation :modelInformation="{id:dataToEdit.id,model:'Projects',toSelect:'Components',relation:'components',include:'sector',type:'one to many'}"/>
        </b-card-body>
        <b-card-footer class="text-center">
          <b-button @click="editField" variant="success"
            ><b-spinner v-if="loading" small></b-spinner> Edit
          </b-button>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Vue from "vue";
import ImagPicker from "../Common/ImageUpload.vue";
import colorPicker from "@caohenghu/vue-colorpicker";
import EditRelation from './EditRelation.vue'
const {
  postFields,
  getFields,
  pathField,
  postDataHeader,
  pathFieldWithOutId,
} = require("../../assets/js/service");

export default {
  name: "editField",
  components: {
    ImagPicker,
    colorPicker,
    EditRelation,
  },

  props: ["addInformation"],
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
    header(value) {
      this.header = value;
      if (!value && this.addInformation.title != "People") {
        this.headererr = true;
      } else {
        this.headererr = null;
      }
    },
    details(value) {
      this.details = value;
      if (
        !value &&
        this.addInformation.title != "Intervention" &&
        this.addInformation.title != "Sector"
      ) {
        this.detailserr = true;
      } else {
        this.detailserr = null;
      }
    },
  },
  data() {
    return {
      theme: "#012970",
      dataToEdit: null,
      suckerCanvas: null,
      bold: false,
      italic: false,
      linkText: null,
      link: null,
      suckerArea: [],
      target:[ {
        title: null,
        number: null,
        archived : 0,
      }],
      vedioEmbedede: null,
      youtubeerr: null,
      isSucking: false,
      optionsForFileType: [
        { text: "PDF", value: "pdf" },
        { text: "Video", value: "video" },
      ],
      years: null,
      fileType: "pdf",
      noOfPages: null,
      noOfPageserr: null,
      yearsEmpity: null,
      filePdferr: null,
      fileSizeerr: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,

      options: [],
      value: [],
      search: "",

      allInterventions: null,
      allComponents: null,
      allSectors: null,
      client: null,
      clienterr: null,
      iconEmpity: null,
      
      targeterr: null,
      Regions: null,
      regionsterr: null,
      Tags: null,
      tags: [],
      TagName: null,
      selectedTag: [],
      header: null,
      fullName: null,
      fullNameerr: null,
      details: null,
      headererr: null,
      detailserr: null,
      quotes: null,
      bannerImageEmpity: null,
      ImageEmpity: null,
      loading: false,
      subNews: [
        {
          header: null,
          details: null,
          quotes: null,
        },
      ],
      subNewsError: [
        {
          headererr: null,
          detailserr: null,
        },
      ],
    };
  },

  mounted() {

    this.options = [];
    let token = localStorage.getItem("token");
    if (this.addInformation.title == "News") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
        this.dataToEdit.subNews.push({
          header: null,
          details: null,
          quotes: null,
        });
        for (let index = 0; index < this.dataToEdit.subNews.length; index++) {
          const element = this.dataToEdit.subNews[index];
          this.subNewsError[index] = {
            headererr: null,
            detailserr: null,
          };
        }
      }
    } else if (this.addInformation.title == "People") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
      }
    } else if (this.addInformation.title == "Sector") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
      }
    } else if (this.addInformation.title == "Intervention") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
      }
    } else if (this.addInformation.title == "Component") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
      }
    } else if (this.addInformation.title == "Project") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
        this.theme = '#' + this.dataToEdit.theme;
      }
    } else if (this.addInformation.title == "Knowledge") {
      if (this.$store.state.singlePageData) {
        this.dataToEdit = this.$store.state.singlePageData;
      }
    }
    if (!this.dataToEdit) {
      this.$router.go(-1);
    }
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    textBold() {
      if (!this.bold) {
        this.dataToEdit.details += "<b>";
        this.bold = true;
      } else {
        this.dataToEdit.details += "</b>";
        this.bold = false;
      }
    },
    textItalic() {
      if (!this.italic) {
        this.dataToEdit.details += "<i>";
        this.italic = true;
      } else {
        this.dataToEdit.details += "</i>";
        this.italic = false;
      }
    },
    addBreak() {
      this.dataToEdit.details += "<br>";
    },
    addLink() {
      if (this.link && this.linkText) {
        this.dataToEdit.details =
          this.dataToEdit.details +
          '<a href="' +
          this.link +
          '">' +
          this.linkText +
          "</a>";
      }
    },

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string

      this.dataToEdit.dateNews = ctx.selectedFormatted;

      // The following will be an empty string until a valid date is entered
    },
    changeColor(color) {
      this.theme = color.hex;
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.selectedSectors = this.search = "";
    },
    apendSelectedTag(tag, index) {

      let temptags = this.tags;
      if (this.tags[index].active) {
        temptags[index].active = false;
        this.tags = temptags;
        this.tags[index]["active"] = false;
        const indextod = this.selectedTag.indexOf(tag.id);
        if (indextod > -1) {
          this.selectedTag.splice(index, 1);
        }
      } else {
        this.selectedTag.push(tag.id);
        temptags[index].active = true;
        this.tags = temptags;
      }
    },

    editField() {
      let fieldData = {
        header: this.header,
        details: this.details,
      };
      let token = localStorage.getItem("token");

      if (!this.dataToEdit.header && this.addInformation.title != "People") {
        this.headererr = true;
      }
      if (!this.dataToEdit.details && this.addInformation.title != "Sector") {
        this.detailserr = true;
      }
      if (!this.headererr && !this.detailserr) {
        if (this.addInformation.title == "News") {
          let database = "news";
          this.bannerImageEmpity = null;
          this.ImageEmpity = null;
          if (
            !this.dataToEdit.subNews[this.dataToEdit.subNews.length - 1]
              .header &&
            !this.dataToEdit.subNews[this.dataToEdit.subNews.length - 1]
              .details &&
            !this.dataToEdit.subNews[this.dataToEdit.subNews.length - 1].quotes
          ) {
            this.dataToEdit.subNews.pop();
            this.subNewsError.pop();
          }
          let validationErrorInSubNews = false;

          for (let index = 0; index < this.dataToEdit.subNews.length; index++) {
            const element = this.dataToEdit.subNews[index];
            if (this.$store.state["images" + (3 + index).toString()][0]) {
              this.dataToEdit.subNews[index].bannerImages =
                this.$store.state["images" + (3 + index).toString()][0];
            }
            if (!element.header) {
              this.subNewsError[index].headererr = true;
              validationErrorInSubNews = true;
            }
            if (!element.details) {
              this.subNewsError[index].detailserr = true;
              validationErrorInSubNews = true;
            }
          }
          if (!validationErrorInSubNews) {
            if (this.$store.state.images[0]) {
              this.dataToEdit.bannerImages = this.$store.state.images[0];
            }
            if (this.$store.state.images1.length) {
              this.dataToEdit.newsImages = this.$store.state.images1;
            }

            this.$store.commit("set", ["loading", true]);
            pathFieldWithOutId(database, this.dataToEdit, token).then(
              (resp) => {
                this.$store.commit("set", ["images", []]);
                this.$store.commit("set", ["images1", []]);
                this.$store.commit("set", ["images2", []]);
                this.$store.commit("set", ["images3", []]);
                this.$store.commit("set", ["images4", []]);
                this.$store.commit("set", ["images5", []]);
                this.$store.commit("set", ["images6", []]);
                this.$store.commit("set", ["images7", []]);
                this.$store.commit("set", ["images8", []]);
                this.$store.commit("set", ["images9", []]);
                this.$store.commit("set", ["images10", []]);
                this.$store.commit("set", ["images11", []]);
                this.$store.commit("set", ["images12", []]);
                this.$store.commit("set", ["images13", []]);
                this.$store.commit("set", ["images14", []]);
                this.$store.commit("set", ["images15", []]);
                this.$store.commit("set", ["singlePageData", resp.data]);
                this.dataToEdit = resp.data;

                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            );
          }
        } else if (this.addInformation.title == "People") {
          let database = "People";

          this.bannerImageEmpity = null;
          this.ImageEmpity = null;
          if (!this.dataToEdit.fullName) {
            this.bannerImageEmpity = false;
            this.fullNameerr = true;
          } else {
            this.fullNameerr = false;
            if (this.$store.state.images[0]) {
              this.dataToEdit.image = this.$store.state.images[0];
            }
            this.$store.commit("set", ["loading", true]);
            pathFieldWithOutId(database, this.dataToEdit, token).then(
              (resp) => {
                this.dataToEdit = resp.data;
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            );
          }
        } else if (this.addInformation.title == "Sector") {

          this.bannerImageEmpity = false;
          if (this.$store.state.images[0]) {
            this.dataToEdit.image = this.$store.state.images[0];
          }
          this.$store.commit("set", ["loading", true]);
          pathFieldWithOutId("Sectors", this.dataToEdit, token).then((resp) => {
            this.dataToEdit = resp.data;
            this.$store.commit("set", ["loading", false]);
            Vue.$toast.success("Successful", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          });
        } else if (this.addInformation.title == "Intervention") {
          if (!this.dataToEdit.regions) {
            this.regionsterr = true;
          } else {
            this.regionsterr = false;
          }
          if (this.dataToEdit.regions) {
            let sectorIds = [];

            if (this.$store.state.images[0]) {
              this.dataToEdit.image = this.$store.state.images[0];
            }

            this.$store.commit("set", ["loading", true]);
            pathFieldWithOutId("Inerventions", this.dataToEdit, token).then(
              (resp) => {
                this.dataToEdit = resp.data;
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            );
          }
        } else if (this.addInformation.title == "Component") {
          this.$store.commit("set", ["loading", true]);
          pathFieldWithOutId("Components", this.dataToEdit, token).then(
            (resp) => {
              //  here here here
              this.dataToEdit = resp.data;
              this.$store.commit("set", ["loading", false]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          );
        } else if (this.addInformation.title == "Project") {
          if (!this.dataToEdit.client) {
            this.clienterr = true;
          } else {
            this.clienterr = false;
          }
          for (let index = 0; index < this.dataToEdit.target.length; index++) {

            
            const element = this.dataToEdit.target[index];
            if(element.title && !element.number){
              this.dataToEdit.target[index].number = 0;
            }
          
            if(!element.title){
              
    this.dataToEdit.target.splice(index, 1);
            }
            }
          if (
            !this.dataToEdit.target.length
          ) {
            this.targeterr = true;
          } else {
            this.targeterr = false;
          }
          if (!this.dataToEdit.years) {
            this.yearsEmpity = true;
          } else {
            this.yearsEmpity = false;
          }

          if (!this.dataToEdit.regions) {
            this.regionsterr = true;
          } else {
            this.regionsterr = false;
          }

          if (
            this.dataToEdit.client &&
            this.dataToEdit.target.length &&
           
            this.dataToEdit.years &&
            this.dataToEdit.regions
          ) {

            if (this.$store.state.images[0]) {
              this.dataToEdit.header_image = this.$store.state.images[0];
            }
            if (this.$store.state.images1[0]) {
              this.dataToEdit.logo_image = this.$store.state.images1[0];
            }
            if (
              this.theme.substr(1, this.theme.length) != this.dataToEdit.theme
            ) {
              this.dataToEdit.theme = this.theme.substr(1, this.theme.length);
            }

            this.$store.commit("set", ["loading", true]);
            pathFieldWithOutId("Projects", this.dataToEdit, token).then(
              (resp) => {
                this.dataToEdit = resp.data;
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            );
          }
        } else if (this.addInformation.title == "Knowledge") {
          if (this.dataToEdit.file_type == "pdf") {

            if (!this.dataToEdit.no_of_pages) {
              this.noOfPageserr = true;
            } else {
              this.noOfPageserr = false;
            }
            if (this.dataToEdit.no_of_pages) {
              if (this.$store.state.images[0]) {
                this.dataToEdit.tumblin = this.$store.state.images[0];
              }
              if (this.$store.state.filePDF.length) {
                this.dataToEdit.pdf = this.$store.state.filePDF;
                this.dataToEdit.file_size = this.$store.state.filePDFSize;
              }

              this.$store.commit("set", ["loading", true]);
              pathFieldWithOutId("Knowledge", this.dataToEdit, token).then(
                (resp) => {
                  this.dataToEdit = resp.data;
                  this.$store.commit("set", ["loading", false]);
                  Vue.$toast.success("Successful", {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,

                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
               }
              );
            }
          } else if (this.dataToEdit.file_type == "video") {
            if (!this.dataToEdit.youtube) {
              this.youtubeerr = true;
            } else {
              this.youtubeerr = false;
            }

            if (this.dataToEdit.youtube) {
              if (this.$store.state.images[0]) {
                this.dataToEdit.tumblin = this.$store.state.images[0];
              }

              this.$store.commit("set", ["loading", true]);
              pathFieldWithOutId("Knowledge", this.dataToEdit, token).then(
                (resp) => {
                  this.dataToEdit = resp.data;
                  this.$store.commit("set", ["loading", false]);
                  Vue.$toast.success("Successful", {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,

                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              );
            }
          }
        }
      }
    },
  },
};
</script>
<style>
</style>